import React, { useEffect, useState } from "react";
import jpr_login_logo from "./jpr_login_logo.png";
import ohs_login_logo from "./ods_login_logo.png";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

interface Resource {
  key: string;
  order: number;
  type?: string;
  title?: string;
  links?: string[];
}

// Get environment variables
let authBaseUrl = process.env.REACT_APP_WEBPORTAL_COGNITO_BASE_URL;
let webAppBaseUrl = process.env.REACT_APP_WEBPORTAL;
let logoutEndpoint = process.env.REACT_APP_WEBPORTAL_COGNITO_LOGOUT_ENDPOINT;
let clientId = process.env.REACT_APP_WEBPORTAL_COGNITO_CLIENTID;
let logoutRedirectEndpoint =
  process.env.REACT_APP_WEBPORTAL_COGNITO_LOGOUT_REDIRECT_URI;
let loginEndpoint = process.env.REACT_APP_WEBPORTAL_COGNITO_LOGIN_ENDPOINT;
let redirectEndpoint = process.env.REACT_APP_WEBPORTAL_COGNITO_REDIRECT_URI;
let apiBaseUrl = process.env.REACT_APP_WEBPORTAL_API_BASE_URL;
let logo =
  process.env.REACT_APP_COMPANY === "ods" ? ohs_login_logo : jpr_login_logo;
let domain =
  process.env.REACT_APP_COMPANY === "ods"
    ? "webportal.ohdservices.com.au"
    : "webportal.jpr.com.au";
let environmentPrefix =
  process.env.REACT_APP_ENV === "prod" ? "" : `${process.env.REACT_APP_ENV}.`;
webAppBaseUrl = `https://${environmentPrefix}${domain}/`;
apiBaseUrl = `https://${environmentPrefix}api.${domain}/`;
authBaseUrl = `https://${environmentPrefix.replace(".", "-")}webportal-${
  process.env.REACT_APP_COMPANY === "ods"
    ? "ohdservices"
    : process.env.REACT_APP_COMPANY
}.auth.ap-southeast-2.amazoncognito.com/`;
switch (process.env.REACT_APP_ENV) {
  case "dev": {
    clientId =
      process.env.REACT_APP_COMPANY === "ods"
        ? "1s8nukbtapspk7t67n7jcic0n7"
        : "2o7rjrv64vpmpgiapis1m07idq";
    break;
  }
  case "test": {
    clientId =
      process.env.REACT_APP_COMPANY === "ods"
        ? "6l5hj7tpakmsk3svgj7e23uf4i"
        : "31329uvtkt3ops9lu9fa79gkn5";
    break;
  }
  case "prod": {
    clientId =
      process.env.REACT_APP_COMPANY === "ods"
        ? "393li4idne4pqlsk5vlm41v474"
        : "4ehpq0g7d55feoe1kueb9k6174";
    break;
  }
}

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          {/* Render a nav menu if this is not the landing page */}
          <Switch>
            <Route path="/landing_page" />
            <Route path="/">
              <nav>
                <div className="nav-logo-container">
                  <img src={logo} className="nav-logo" alt="logo" />
                </div>
              </nav>
              <div className="user">
                <div>
                  <div className="column fill"></div>
                  <div className="column">
                    <FontAwesomeIcon icon={faUserCircle} className="fa-2x" />
                  </div>
                  <div className="column">
                    <a
                      href={`${authBaseUrl}${logoutEndpoint}?client_id=${clientId}&logout_uri=${webAppBaseUrl}${logoutRedirectEndpoint}`}
                    >
                      Sign Out
                    </a>
                  </div>
                </div>
              </div>
            </Route>
          </Switch>

          {/* Render the appropriate page */}
          <Switch>
            <Route path="/landing_page">
              <LandingPage />
            </Route>
            <Route path="/auth_response">
              <AuthResponse />
            </Route>
            <Route path="/logged_out">
              <LoggedOut />
            </Route>
            <PrivateRoute path="/resources">
              <Resources />
            </PrivateRoute>
            <Route path="/">
              {/* Default to landing_page if route doesn't match */}
              <Redirect
                to={{
                  pathname: "/landing_page",
                }}
              />
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
interface PrivateRouteProps extends RouteProps {
  children: React.ReactNode;
}
function PrivateRoute({ children, ...rest }: PrivateRouteProps) {
  let authenticated = false;
  let headers = axios.defaults.headers.common;
  if (headers?.Authorization) authenticated = true;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        authenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function LandingPage() {
  return (
    <header className="App-header">
      <div className="login-logo-container">
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      <h1>Web Portal</h1>
      <a
        className="LandingPage-link"
        href={`${authBaseUrl}${loginEndpoint}?client_id=${clientId}&response_type=token&scope=email+openid+profile&redirect_uri=${webAppBaseUrl}${redirectEndpoint}`}
        target="_self"
        rel="noopener noreferrer"
      >
        Sign in
      </a>
    </header>
  );
}

function AuthResponse() {
  // Get access token from auth response.
  let hash = window.location.hash.substr(1);
  let parameterStrings = hash.split("&");
  let idToken = "";
  parameterStrings.forEach((param) => {
    let split = param.split("=");
    switch (split[0]) {
      case "id_token":
        idToken = split[1];
        break;
    }
  });

  if (idToken) {
    if (!window.location.origin) console.log("Origin undefined");
    // Include the access token in future requests.
    axios.defaults.baseURL = apiBaseUrl;
    axios.defaults.headers.common = {
      Authorization: `Bearer ${idToken}`,
    };

    return (
      <Redirect
        to={{
          pathname: "/resources",
        }}
      />
    );
  }

  // If we hit this point, something unexpected happened. Take user back to start.
  return (
    <Redirect
      to={{
        pathname: "/",
      }}
    />
  );
}

function LoggedOut() {
  axios.defaults.headers.common = { Authorization: "" };

  return (
    <Redirect
      to={{
        pathname: "/",
      }}
    />
  );
}

function Resources() {
  const [currentDirectory, setCurrentDirectory] = useState(" ");
  const [resources, setResources] = useState<Resource[]>([]);
  const [currentDocuments, setCurrentDocuments] = useState<Resource[]>([]);
  const [currentLinks, setCurrentLinks] = useState<Resource[]>([]);
  const [currentFolders, setCurrentFolders] = useState<string[]>([]);

  // If current directory changes, invoke handler
  useEffect(handleChangeDirectory, [currentDirectory]);

  // On load, get resources and then set the current directory
  useEffect(() => {
    axios.get("resources").then(
      (response) => {
        setResources(response.data.results);
        setCurrentDirectory("");
      },
      (error) => {
        console.log("Error getting resources for user: " + error);
      }
    );
  }, []);

  return (
    <div className="content">
      <h1 className="page-title">Resources</h1>
      Folder:{" "}
      <a className="App-link" onClick={() => setCurrentDirectory("")}>
        Home
      </a>
      {currentDirectory &&
        currentDirectory.split("/").map((breadCrumb, index) => (
          <>
            {" / "}
            <a
              className="App-link"
              onClick={() =>
                setCurrentDirectory(
                  currentDirectory.substring(
                    0,
                    currentDirectory
                      .split("/")
                      .slice(0, index + 1)
                      .reduce((prev, curr) => curr + prev).length + index
                  )
                )
              }
            >
              {breadCrumb}
            </a>
          </>
        ))}
      <h2>Documents</h2>
      {!currentDocuments?.length && "No resources in this folder."}
      {currentDocuments.length > 0 && (
        <ul>
          {currentDocuments
            .filter((resource) => resource.type !== "link")
            .map((resource, index) => (
              <li key={index}>
                <a
                  className="App-link"
                  onClick={() => {
                    axios
                      .get(
                        `resources/${encodeURIComponent(
                          currentDirectory +
                            (currentDirectory ? "/" : "") +
                            resource.key
                        )}`
                      )
                      .then(
                        (response) => {
                          const data = response.data.results.url;
                          window.open(data, "_blank");
                        },
                        (error) => {
                          console.log("Error downloading resource: " + error);
                        }
                      );
                  }}
                >
                  {resource.title ?? resource.key}
                </a>
              </li>
            ))}
        </ul>
      )}
      {currentLinks?.length > 0 && (
        <>
          <h2>Links</h2>
          {currentLinks.map((resource, index) => (
            <>
              <p>{resource.title ?? resource.key}:</p>
              <ul>
                {resource.links?.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <a
                      className="App-link"
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {link}
                    </a>
                  </li>
                ))}
              </ul>
            </>
          ))}
        </>
      )}
      <h2>See More</h2>
      {!currentFolders?.length && "No more folders."}
      {currentFolders.length > 0 && (
        <ul>
          {currentFolders.map((folder, index) => (
            <li key={index}>
              <a
                className="App-link"
                onClick={() =>
                  setCurrentDirectory(
                    currentDirectory + (currentDirectory ? "/" : "") + folder
                  )
                }
              >
                {folder}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  function handleChangeDirectory() {
    let tempCurrentDocuments: Resource[] = [];
    let tempCurrentLinks: Resource[] = [];
    let tempCurrentDirectories: string[] = [];

    // Find children of the current directory
    resources
      .filter((resource) => resource.key.startsWith(currentDirectory))
      .forEach((resource) => {
        const pathAfterCurrentDirectory = resource.key.substring(
          currentDirectory.length + (currentDirectory ? 1 : 0)
        );
        const pathParts = pathAfterCurrentDirectory.split("/");
        if (pathParts.length > 1) {
          // If it's a folder, add to list of folders
          if (tempCurrentDirectories.indexOf(pathParts[0]) === -1)
            tempCurrentDirectories.push(pathParts[0]);
        } else if (resource.type === "link") {
          // If it's a link, add to list of links
          tempCurrentLinks.push({
            key: pathParts[0],
            order: +resource.order || 1000000,
            type: resource.type,
            title: resource.title,
            links: resource.links?.map((link) => decodeURIComponent(link)),
          });
        } else if (pathParts[0].includes(".")) {
          // If it's a file, add to list of files
          tempCurrentDocuments.push({
            key: pathParts[0],
            order: +resource.order || 1000000,
            type: resource.type,
            title: resource.title,
          });
        }
      });

    // Update current folders and resources
    setCurrentFolders(
      tempCurrentDirectories
        .filter((dir) => !!dir) // Account for hidden files used by S3 as folder placeholders
        .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
    );
    setCurrentDocuments(
      tempCurrentDocuments.sort((a, b) =>
        a.order < b.order ? -1 : a.order > b.order ? 1 : 0
      )
    );
    setCurrentLinks(
      tempCurrentLinks.sort((a, b) =>
        a.order < b.order ? -1 : a.order > b.order ? 1 : 0
      )
    );
  }
}

export default App;
